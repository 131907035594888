import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Menu } from "antd";
import { SettingOutlined, FileOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { matchPath, useHistory, useLocation } from "react-router-dom";

const { SubMenu } = Menu;

const AppMenu = () => {
  const { roles } = useSelector((store) => store.user);
  const history = useHistory();
  const location = useLocation();

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const menu = useMemo(
    () => [
      {
        name: "admin",
        label: "Администрирование",
        icon: <SettingOutlined />,
        path: "/app/admin",
        available: true,
        children: [
          {
            name: "users",
            label: "Пользователи",
            path: "/app/admin/users",
            available: true,
          },
        ],
      },
      {
        name: "inspection",
        label: "Техосмотр",
        icon: <FileOutlined />,
        path: "/app/inspection",
        available: true,
        children: [
          {
            name: "inspectionRequests",
            label: "Заявки",
            path: "/app/inspection/requests",
            available: true,
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    const checkActive = (name) =>
      !!matchPath(location.pathname, {
        exact: false,
        strict: false,
        path: name,
      });

    const newOpenKeys = menu
      .filter((section) => checkActive(section.path))
      .map((section) => section.name);

    const newSelectedKeys = menu
      .reduce((acc, curr) => [...acc, ...curr.children], [])
      .filter((action) => checkActive(action.path))
      .map((action) => action.path);

    setOpenKeys(newOpenKeys);
    setSelectedKeys(newSelectedKeys);
  }, [location.pathname, roles, menu]);

  const onSelect = useCallback(
    ({ key }) => {
      history.push(key);
    },
    [history]
  );

  const onOpenChange = (newOpenKeys) => {
    console.log(newOpenKeys);
    setOpenKeys(newOpenKeys);
  };

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      style={{ height: "100%", borderRight: 0 }}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
    >
      {menu
        .filter(({ available }) => available)
        .map((module) => (
          <SubMenu key={module.name} icon={module.icon} title={module.label}>
            {module.children
              .filter(({ available }) => available)
              .map((action) => (
                <Menu.Item key={action.path}>{action.label}</Menu.Item>
              ))}
          </SubMenu>
        ))}
    </Menu>
  );
};

export default AppMenu;
