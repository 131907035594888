import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import List from './list/List'

const Index = () => {

	return <Switch>
		<Route path={'/app/admin/users/list'} component={List}/>
		<Redirect to={'/app/admin/users/list'} />
	</Switch>;
};

export default Index;
