import { useMemo } from "react";
import Api from "../services/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../store/app";

const useInspectionRequestContainer = ({ afterSave }) => {
  const dispatch = useDispatch();
  const {
    roles: [userRole],
  } = useSelector((store) => store.user);
  const { employee, stations } = useSelector((store) => store.app);
  const { purposes, payments, categories } = useSelector((store) => store.inspection);

  const initialValues = useMemo(
    () => ({
        registration_number: "",
        brand: "",
        model: "",
        purpose: 0,
        category: "",
        payer: "",
        payer_phone_number: "",
        employee_id: 0,
        creator_id: 0,
        parent_id: 0,
        station_id: 0,
        payment_type: 0,
        client_price: 0,
        special_notes: "",
        card_link: "",
    }),
    []
  );

  const validationSchema = Yup.object().shape({
      registration_number: Yup.string(),
      brand: Yup.string().required("Обязательное поле"),
      model: Yup.string().required("Обязательное поле"),
      purpose: Yup.number().required("Обязательное поле").oneOf(purposes.map(purpose => purpose.value)),
      category: Yup.string().required("Обязательное поле").oneOf(categories.map(category => category.value)),
      payer: Yup.string().required("Обязательное поле"),
      payer_phone_number: Yup.string().required("Обязательное поле"),
      employee_id: Yup.number().required("Обязательное поле").oneOf(employee.map(i => i.id)),
      creator_id: Yup.number().required("Обязательное поле"),
      parent_id: Yup.number().required("Обязательное поле"),
      station_id: Yup.number().required("Обязательное поле").oneOf(stations.map((station) => station.id)),
      payment_type: Yup.number().required("Обязательное поле").oneOf(payments.map((payment) => payment.id)),
      client_price: Yup.number().required("Обязательное поле"),
      special_notes: Yup.string(),
      card_link: Yup.string(),
  });

  const onSubmit = async (values, formik) => {
    try {
      const { data } = await Api("inspection-requests/form", values, "post");
      formik.resetForm();
      afterSave(data);
    } catch (e) {
      if (e.response) {
        // Set backend errors
        if (e.response?.data?.errors) {
          Object.entries(e.response.data.errors).forEach(([field, errors]) => {
            formik.setFieldError(field, errors[0]);
          });
        }
      } else if (e.request) {
        // Request problem
        console.log("request");
      } else {
        // Weird
      }
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  return { formik, purposes, payments, categories, stations, employee };
};

export default useInspectionRequestContainer;
