import React, { useState, useEffect, useMemo, useCallback } from "react";
import { PageHeader, Button, Table, Modal } from "antd";
import styles from "./styles.module.scss";
import RoleTag from "../../../../../components/admin/users/role-tag/RoleTag";
import Api from "../../../../../services/api";
import Form from "../form/Form";
import {useSelector} from "react-redux";
import Details from "../../../../../components/admin/users/details/Details";

const List = () => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const {canCreateUsers} = useSelector(store => store.user)

  const pageSize = 10;

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Логин",
        dataIndex: "login",
        sorter: true,
        width: "20%",
      },

      {
        title: "ФИО",
        dataIndex: "name",
        sorter: true,
      },

      {
        title: "E-mail",
        dataIndex: "email",
      },

      {
        title: "Телефон",
        dataIndex: "phone",
      },

      {
        title: "Роль",
        dataIndex: "role",
        render: (role) => <RoleTag role={role} />,
        /*filters: [
          { text: "Администратор", value: "admin" },
          { text: "Сотрудник", value: "employee" },
          { text: "Партнер", value: "partner" },
          { text: "Компания", value: "company" },
        ],*/
        width: "20%",
      },

    ],
    []
  );

  const fetchUsers = useCallback(
    async ({ pagination, sorter } = {}) => {
      setLoading(true);

      const params = {
        currentPage: pagination?.current || currentPage,
        pageSize,
        sorter: sorter ? { [sorter.field]: sorter.order } : null,
      };

      const response = await Api("users/list", params);
      const totalCount = parseInt(response.headers["x-count"], 10);
      setUsers(response.data);
      setTotalCount(totalCount);
      setCurrentPage(params.currentPage);

      setLoading(false);
    },
    [currentPage]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const afterFormSave = () => {
    setShowAddModal(false);
    fetchUsers();
  };

  const onTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    fetchUsers({ pagination, sorter });
  };


  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Пользователи"
        subTitle={`${totalCount} записей`}
        extra={[
          <Button key={0} type="primary" disabled={!canCreateUsers} onClick={() => setShowAddModal(true)}>
            Добавить
          </Button>,
        ]}
      />

      <Table
        className={styles.table}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={users}
        pagination={{ current: currentPage, total: totalCount, pageSize }}
        loading={loading}
        expandedRowRender={user => <Details user={user} afterChangePrices={fetchUsers}/>}
        rowExpandable={() => true}
        expandable={{expandRowByClick: true}}
        onChange={onTableChange}
      />

      <Modal
        footer={false}
        title={"Добавить пользователя"}
        visible={showAddModal}
        onCancel={() => setShowAddModal(false)}
        destroyOnClose={true}
      >
        <Form type={"create"} afterSave={afterFormSave} />
      </Modal>
    </>
  );
};

export default List;
