import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import List from './list/List'

const Index = () => {
    return <Switch>
        <Route path={'/app/inspection/requests'} component={List}/>
        <Redirect to={'/app/inspection/requests'}/>
    </Switch>;
};

export default Index;
