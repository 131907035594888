import React from "react";
import { Layout } from "antd";
import AppMenu from "./menu/Menu";
import Header from "./header/Header";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const AppLayout = ({ children }) => {
  const { isAuthorized } = useSelector((store) => store.user);

  return (
    <Layout>
      <Header />
      <Layout>
        <Sider width={isAuthorized ? 250 : 0}>
          <AppMenu />
        </Sider>
        <Layout>
          {children}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
