import React from 'react';
import {Col, Modal, Row} from "antd";
import {DatePicker, Form, Input} from "formik-antd";
import {FormikProvider} from "formik";
import usePriceContainer from "../../../../hooks/usePriceContainer";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import _ from 'lodash'


const PriceForm = ({show, onCancel, afterSave, userId}) => {

    const afterSubmit = () => {
        afterSave()
        onCancel();
    }

    const {formik, categories} = usePriceContainer({afterSave: afterSubmit, userId});

    return (<Modal
        title={"Добавление цены"}
        visible={show}
        onCancel={onCancel}
        okText={"Добавить"}
        cancelText={"Отмена"}
        onOk={formik.handleSubmit}
    >
        <FormikProvider value={formik}>
            <Form layout={"vertical"}>

                {_.chunk(categories, 2).map(row =>
                    <Row gutter={16} key={row}>
                        {row.map(col => <Col key={col} span={12}>
                            <Form.Item name={col} label={col.toUpperCase()}><Input name={col}/></Form.Item>
                        </Col>)}
                    </Row>)}

                <Form.Item name="startsAt" label="Действует с">
                    <DatePicker
                        name="startsAt"
                        placeholder={"Выбрать дату"}
                        format={"D MMM y"}
                        locale={locale}
                        style={{width: '100%'}}
                    />
                </Form.Item>
            </Form>
        </FormikProvider>
    </Modal>);
}

export default PriceForm;
