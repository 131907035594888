import React, {useMemo, useState} from 'react';
import {Button, Modal, Space, Table, Typography} from 'antd'
import Api from "../../../../services/api";
import PriceForm from "../price-form/PriceForm";

const Details = ({user, afterChangePrices}) => {
    const [showPriceForm, setShowPriceForm] = useState(false)

    const onDeletePress = async (id) => {
        Modal.confirm({
            title: "Удаление цен",
            content: "Действительно удалить цены?",
            okText: "Удалить",
            cancelText: "Отмена",
            onOk: async () => {
                await Api("prices/delete", {id})
                await afterChangePrices();
                return true;
            }
        })
    }

    const categories = ["l", "m1", "m2", "m3", "n1", "n2", "n3", "o12", "o34"]

    const columns = useMemo(
        () => [

            ...categories.map(category => ({title: category.toUpperCase(), dataIndex: category})),

            {
                title: "Действует с",
                dataIndex: "activation_date",
            },

            {
                title: "Активна",
                dataIndex: "id",
                render: id => id === user.activePrice?.id ? "Да" : "Нет"
            },

            {
                title: "Управление",
                dataIndex: "id",
                render: (id) => <div>
                    <Button onClick={() => onDeletePress(id)} danger
                            disabled={id === user.activePrice?.id}>Удалить</Button>
                </div>
            },

        ],
        [user]
    );

    return (<div>

        <Space align="baseline" size={16}>
            <Typography.Title level={5}>Цены</Typography.Title>
            <Button onClick={() => setShowPriceForm(true)}>Добавить</Button>
        </Space>


        <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={user.prices}
            pagination={false}
        />

       <PriceForm
           userId={user.id}
           afterSave={afterChangePrices}
           show={showPriceForm}
           onCancel={() => setShowPriceForm(false)}
       />

    </div>);
}

export default Details;
