import React from "react";
import useUserContainer from "../../../../../hooks/useUserContainer";
import {
  Form as AntdForm,
  Input,
  Select,
  Checkbox,
  SubmitButton,
} from "formik-antd";
import { FormikProvider } from "formik";
import { useSelector } from "react-redux";

const Form = ({ type, afterSave }) => {
  const { formik, availableRoles, fieldsForRoles } = useUserContainer({
    afterSave,
  });
  const { roles, positions, employee, stations } = useSelector(
    (store) => store.app
  );

  const additionalFields = fieldsForRoles[formik.values.role] || [];

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    layout: "horizontal",
  };


  return (
    <FormikProvider value={formik}>
      <AntdForm {...layout}>
        <AntdForm.Item name="login" label="Логин">
          <Input name="login" />
        </AntdForm.Item>

        <AntdForm.Item name="password" label="Пароль">
          <Input name="password" />
        </AntdForm.Item>

        <AntdForm.Item name="name" label="ФИО">
          <Input name="name" />
        </AntdForm.Item>

        <AntdForm.Item name="phone" label="Телефон">
          <Input name="phone" />
        </AntdForm.Item>

        <AntdForm.Item name="role" label="Роль">
          <Select name="role">
            {roles
              .filter((role) => availableRoles.includes(role.name))
              .map((role) => (
                <Select.Option key={role.name} value={role.name}>
                  {role.title}
                </Select.Option>
              ))}
          </Select>
        </AntdForm.Item>

        {additionalFields.includes("email") && (
          <AntdForm.Item name="email" label="E-mail">
            <Input name="email" />
          </AntdForm.Item>
        )}

        {additionalFields.includes("title") && (
          <AntdForm.Item name="title" label="Наименование">
            <Input name="title" />
          </AntdForm.Item>
        )}

        {additionalFields.includes("position") && (
          <AntdForm.Item name="position" label="Должность">
            <Select name="position">
              {positions.map((position) => (
                <Select.Option key={position.id} value={position.id}>
                  {position.name}
                </Select.Option>
              ))}
            </Select>
          </AntdForm.Item>
        )}

        {additionalFields.includes("station") && (
          <AntdForm.Item name="station" label="Станция ПТО">
            <Select name="station">
              {stations.map((station) => (
                <Select.Option key={station.id} value={station.id}>
                  {station.name}
                </Select.Option>
              ))}
            </Select>
          </AntdForm.Item>
        )}

        {additionalFields.includes("employee") && (
          <AntdForm.Item name="employee" label="Куратор">
            <Select name="employee">
              {employee.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </AntdForm.Item>
        )}

        {additionalFields.includes("inn") && (
          <AntdForm.Item name="inn" label="ИНН">
            <Input name="inn" />
          </AntdForm.Item>
        )}

        {additionalFields.includes("paymentType") && (
          <AntdForm.Item name="paymentType" label="Вид оплаты">
            <Select name="paymentType">
              <Select.Option value={"cash"}>Наличными</Select.Option>
              <Select.Option value={"card"}>Перевод на карту</Select.Option>
              <Select.Option value={"invoice"}>По счету</Select.Option>
            </Select>
          </AntdForm.Item>
        )}

        {additionalFields.includes("postPayment") && (
          <AntdForm.Item name="postPayment" wrapperCol={{ offset: 6 }}>
            <Checkbox name="postPayment">Пост-оплата</Checkbox>
          </AntdForm.Item>
        )}

        {additionalFields.includes("issueInspectionCards") && (
          <AntdForm.Item name="issueInspectionCards" wrapperCol={{ offset: 6 }}>
            <Checkbox name="issueInspectionCards">Выдача ДК</Checkbox>
          </AntdForm.Item>
        )}

        {additionalFields.includes("createUsers") && (
          <AntdForm.Item name="createUsers" wrapperCol={{ offset: 6 }}>
            <Checkbox name="createUsers">
              Создание дочерних пользователей
            </Checkbox>
          </AntdForm.Item>
        )}

        <SubmitButton block type={"primary"} disabled={false}>
          Сохранить
        </SubmitButton>
      </AntdForm>
    </FormikProvider>
  );
};

export default Form;
