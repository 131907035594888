import {useMemo} from "react";
import Api from "../services/api";
import {useFormik} from "formik";
import * as Yup from "yup";
import "yup-phone";
import moment from "moment";

const usePricesContainer = ({afterSave, userId}) => {

    const categories = ["l", "m1", "m2", "m3", "n1", "n2", "n3", "o12", "o34"]

    const initialValues = useMemo(
        () => ({
            ...categories.reduce((result, category) => ({...result, [category]: 0}), {}),
            userId,
            startsAt: moment(),
        }),
        []
    );

    const validationSchema = Yup.object().shape({
        ...categories
            .reduce((result, category) => ({
                ...result,
                [category]: Yup.number().required("Обязательное поле")}
            ), {}),
        userId: Yup.number().required("Обязательное поле"),
    });

    const onSubmit = async (values, formik) => {

        await Api("users/add-price", values, "post");
        formik.resetForm();
        afterSave();
    };

    const formik = useFormik({initialValues, validationSchema, onSubmit});
    return {formik, categories};
};

export default usePricesContainer;
