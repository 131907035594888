export const types = {
  SIGN_IN: "USER/SIGN_IN",
  SIGN_OUT: "USER/SIGN_OUT",
  SET_DATA: "USER/SET_DATA",
  ADD_FRIEND: "USER/ADD_FRIEND",
  REMOVE_FRIEND: "USER/REMOVE_FRIEND",
};

const initialState = {
  purposes: [
    { title: "Личный транспорт", value: 0 },
    { title: "Такси", value: 1 },
    { title: "Учебный транспорт", value: 2 },
    { title: "Перевозка опасных грузов", value: 3 },
  ],
  statuses: [
    { title: "Создана", value: 0 },
    { title: "Карта выдана", value: 1 },
    { title: "Отклонена", value: 2 },
    { title: "Есть ошибки", value: 3 },
    { title: "Удалена", value: 4 },
  ],
  payments: [
    { title: "На ПТО", value: 0 },
    { title: "Пост-оплата", value: 1 },
    { title: "По счету", value: 2 },
    { title: "На банк. карту", value: 3 },
  ],
  categories: [
    { title: "A (L)", value: "l" },
    { title: "B (M1)", value: "m1" },
    { title: "B (N1)", value: "n1" },
    { title: "D (M2)", value: "m2" },
    { title: "D (M3)", value: "m3" },
    { title: "C (N2)", value: "n2" },
    { title: "C (N3)", value: "n3" },
    { title: "E (O1-O2)", value: "o12" },
    { title: "E (O3-O4)", value: "o34" },
  ]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {


    default:
      return state;
  }
};

export default reducer;

export const actions = {

};
