import React, { useState, useEffect, useCallback } from "react";
import IndexRouter from "./pages";
import { useDispatch } from "react-redux";
import { actions } from "./store/user";
import "./app.scss";

const App = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const authorize = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    await dispatch(actions.signIn(token));
    return true;
  }, [dispatch]);

  const init = useCallback(async () => {
    await authorize();

    setLoaded(true);
  }, [authorize]);

  useEffect(() => {
    init();
  }, [init]);

  return <main>{loaded && <IndexRouter />}</main>;
};

export default App;
