import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import AppRouter from "./app";
import AuthRouter from "./auth";
import Layout from "../components/layout/Layout";

const IndexRouter = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path={"/app"} component={AppRouter} />
          <Route path={"/auth"} component={AuthRouter} />
          <Redirect to={"/app"} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default IndexRouter;
