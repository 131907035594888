import Api from "../services/api";
import {actions as appActions} from "./app";

export const types = {
  SIGN_IN: "USER/SIGN_IN",
  SIGN_OUT: "USER/SIGN_OUT",
  SET_DATA: "USER/SET_DATA",
  ADD_FRIEND: "USER/ADD_FRIEND",
  REMOVE_FRIEND: "USER/REMOVE_FRIEND",
};

const initialState = {
  token: null,
  isAuthorized: false,
  login: null,
  roles: [],
  canCreateUsers: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIGN_IN:
      return {
        ...state,
        token: action.payload,
        isAuthorized: true,
      };

    case types.SIGN_OUT:
      return initialState;

    case types.SET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case types.ADD_FRIEND:
      return {
        ...state,
        friends: [...state.friends, action.payload],
      };

    case types.REMOVE_FRIEND:
      return {
        ...state,
        friends: state.friends.filter((f) => f.id !== action.payload),
      };

    default:
      return state;
  }
};

export default reducer;

export const actions = {
  signIn: (token) => async (dispatch) => {
    await localStorage.setItem("token", token);

    await dispatch({
      type: types.SIGN_IN,
      payload: token,
    });

    //try {

      // Get user information
      const { data } = await Api("users/get?no-cache");
      console.log(data)
      await dispatch({
        type: types.SET_DATA,
        payload: data.user,
      });

   /* } catch (e) {

      localStorage.removeItem("token");
      await dispatch({
        type: types.signOut,
      });
      return false;
    }*/

    // Set up application
    await dispatch(appActions.load())

  },

  signOut: () => async (dispatch) => {
    await localStorage.removeItem("token");
    dispatch({
      type: types.SIGN_OUT,
    });
  },

  setData: (data) => async (dispatch) => {
    await dispatch({
      type: types.SET_DATA,
      payload: data,
    });
  },

  addFriend: (friend) => async (dispatch) => {
    await dispatch({
      type: types.ADD_FRIEND,
      payload: {
        name: friend.name,
        avatar: friend.avatar,
        id: friend.id,
        createdAt: friend.createdAt,
        stats: friend.stats,
      },
    });
  },

  removeFriend: (friend) => async (dispatch) => {
    await dispatch({
      type: types.REMOVE_FRIEND,
      payload: friend.id,
    });
  },
};
