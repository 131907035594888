import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./store";
import { Provider as StoreProvider } from "react-redux";

ReactDOM.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
  document.getElementById("root")
)

