import { useMemo } from "react";
import Api from "../services/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../store/app";

const useUserContainer = ({ afterSave }) => {
  const dispatch = useDispatch();
  const {
    roles: [userRole],
  } = useSelector((store) => store.user);
  const { employee, stations, positions } = useSelector((store) => store.app);

  const fieldsForRoles = useMemo(() => {
    const fields = {
      admin: [],
      employee: ["position", "station"],
      companyEmployee: ["email"],
      partner: ["email", "station", "employee", "title", "createUsers"], // todo add prices
      company: [
        "inn",
        "email",
        "title",
        "station",
        "employee",
        "paymentType",
        "postPayment",
        "issueInspectionCards",
        "createUsers",
      ], // todo add prices
    };

    switch (userRole) {
      case "partner":
        fields.partner.splice(fields.partner.indexOf("employee"), 1);
        break;

      default:
        break;
    }

    return fields;
  }, [userRole]);

  let availableRoles = useMemo(() => {
    switch (userRole) {
      case "admin":
        return ["admin", "partner", "company", "employee"];

      case "employee":
        return ["partner", "company"];

      case "company":
        return ["companyEmployee"];

      case "partner":
        return ["partner", "company"];

      default:
        return [];
    }
  }, [userRole]);

  const initialValues = useMemo(
    () => ({
      login: "",
      password: "",
      role: availableRoles[0],
      phone: "",
      email: "",
      name: "",
      position: null,
      station: null,
      title: "",
      employee: null,
      inn: null,
      paymentType: null,
      postPayment: false,
      issueInspectionCards: false,
      createUsers: false,
      // todo add prices
    }),
    [availableRoles]
  );

  const validationSchema = Yup.object().shape({
    login: Yup.string().required("Обязательное поле"),
    password: Yup.string().required("Обязательное поле"),
    role: Yup.string().required("Обязательное поле").oneOf(availableRoles),
    phone: Yup.string()
      .required("Обязательное поле")
      .phone("RU", false, "Неверный формат"),

    name: Yup.string().required("Обязательное поле"),
    email: Yup.string()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("email"),
        then: Yup.string()
          .email("Неверный формат")
          .typeError("Обязательное поле")
          .required("Обязательное поле"),
      }),

    position: Yup.number()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("position"),
        then: Yup.number()
          .typeError("Обязательное поле")
          .required("Обязательное поле")
          .oneOf(positions.map((position) => position.id)),
      }),

    station: Yup.number()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("station"),
        then: Yup.number()
          .typeError("Обязательное поле")
          .required("Обязательное поле")
          .oneOf(stations.map((station) => station.id)),
      }),

    title: Yup.string()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("title"),
        then: Yup.string()
          .typeError("Обязательное поле")
          .required("Обязательное поле"),
      }),

    employee: Yup.number()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("employee"),
        then: Yup.number()
          .typeError("Обязательное поле")
          .required("Обязательное поле")
          .oneOf(employee.map((user) => user.id)),
      }),

    inn: Yup.string()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("inn"),
        then: Yup.string()
          .typeError("Обязательное поле")
          .required("Обязательное поле"),
      }),

    paymentType: Yup.string()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("paymentType"),
        then: Yup.string()
          .typeError("Обязательное поле")
          .required("Обязательное поле")
          .oneOf(["card", "cash", "invoice"]),
      }),

    postPayment: Yup.boolean()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("postPayment"),
        then: Yup.boolean()
          .typeError("Обязательное поле")
          .required("Обязательное поле"),
      }),

    issueInspectionCards: Yup.boolean()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("issueInspectionCards"),
        then: Yup.boolean()
          .typeError("Обязательное поле")
          .required("Обязательное поле"),
      }),

    createUsers: Yup.boolean()
      .nullable()
      .when("role", {
        is: (role) => fieldsForRoles[role].includes("createUsers"),
        then: Yup.boolean()
          .typeError("Обязательное поле")
          .required("Обязательное поле"),
      }),
  });

  const onSubmit = async (values, formik) => {
    try {
      const { data } = await Api("users/form", values, "post");
      dispatch(actions.load());
      formik.resetForm();
      afterSave(data);
    } catch (e) {
      if (e.response) {
        // Set backend errors
        if (e.response?.data?.errors) {
          Object.entries(e.response.data.errors).forEach(([field, errors]) => {
            formik.setFieldError(field, errors[0]);
          });
        }
      } else if (e.request) {
        // Request problem
        console.log("request");
      } else {
        // Weild
      }
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  return { formik, availableRoles, fieldsForRoles };
};

export default useUserContainer;
