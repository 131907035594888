import React from "react";
import { Tag } from "antd";
import { useSelector } from "react-redux";

const RoleTag = ({ role = "company" }) => {
  const { roles } = useSelector((store) => store.app);
  role = roles.find((r) => r.name === role);

  if (!role) return null;

  return <Tag color={role.color}>{role.title.toUpperCase()}</Tag>;
};

export default RoleTag;
