import { useDispatch } from "react-redux";
import Api from "../services/api";
import { actions } from "../store/user";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const useLoginContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    login: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    login: Yup.string().required("Обязательное поле"),
    password: Yup.string().required("Обязательное поле"),
  });

  const onSubmit = async (values, formik) => {

    const {data} = await Api('auth/login', values, "post");
    await dispatch(actions.signIn(data.token));
    history.replace("/app");
  };

  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
};

export default useLoginContainer;
