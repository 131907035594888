import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Api from "../../../../../services/api";
import {Button, PageHeader, Table} from "antd";
import styles from "./styles.module.scss";
import Form from '../form/Form'

const List = () => {
    const [requests, setRequests] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const pageSize = 10;

    const columns = useMemo(
        () => [
            {
                title: "ID",
                dataIndex: "id",
                sorter: true,
            },
        ],
        []
    );

    const fetchRequests = useCallback(
        async ({pagination, sorter} = {}) => {
            setLoading(true);

            const params = {
                currentPage: pagination?.current || currentPage,
                pageSize,
                sorter: sorter ? {[sorter.field]: sorter.order} : null,
            };

            const response = await Api("inspection-requests/list", params);
            const totalCount = parseInt(response.headers["x-count"], 10);
            setRequests(response.data);
            setTotalCount(totalCount);
            setCurrentPage(params.currentPage);

            setLoading(false);
        },
        [currentPage]
    );

    useEffect(() => {
        fetchRequests();
    }, [fetchRequests]);

    const afterFormSave = () => {
        setShowAddModal(false);
        fetchRequests();
    };

    const onTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        fetchRequests({pagination, sorter});
    };


    return (<>
        <PageHeader
            className="site-page-header"
            title="Заявки на техосмотр"
            subTitle={`${totalCount} записей`}
            extra={[
                <Button key={0} type="primary" onClick={() => setShowAddModal(true)}>Добавить</Button>,
            ]}
        />

        <Table
            className={styles.table}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={requests}
            pagination={{current: currentPage, total: totalCount, pageSize}}
            loading={loading}
            onChange={onTableChange}
        />

        <Form
            show={showAddModal}
            onCancel={() => setShowAddModal(false)}
            afterSave={afterFormSave}
        />
    </>);
}

export default List;
