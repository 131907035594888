import React, { useLayoutEffect } from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import {useSelector} from "react-redux";
import AdminRouter from './admin'
import InspectionRouter from './inspection'

const Index = () => {
  const history = useHistory();
  const { isAuthorized } = useSelector((store) => store.user);

  useLayoutEffect(() => {
    if (!isAuthorized) history.replace("/auth");
  }, [isAuthorized, history]);

  if (!isAuthorized) return null;

  return <Switch>
    <Route path={'/app/admin'} component={AdminRouter} />
    <Route path={'/app/inspection'} component={InspectionRouter} />
  </Switch>;
};

export default Index;
