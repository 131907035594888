import React, { useLayoutEffect } from "react";
import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import {useSelector} from "react-redux";
import Login from "./login/Login";

const Index = () => {
    const history = useHistory();
    const { isAuthorized } = useSelector((store) => store.user);

    useLayoutEffect(() => {
        if (isAuthorized) history.replace("/app");
    }, [isAuthorized, history]);

    if (isAuthorized) return null;

    return <Switch>
        <Route path={'/auth/login'} component={Login} />
        <Redirect to={'/auth/login'} />
    </Switch>;
};

export default Index;
