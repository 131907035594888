import React from 'react';
import {Modal} from "antd";
import useInspectionRequestContainer from "../../../../../hooks/useInspectionRequestContainer";
import {FormikProvider} from "formik";
import {
    Form as AntdForm,
    Input,
    Select,
    Checkbox,
    SubmitButton,
} from "formik-antd";
const Form = ({show, onCancel, afterSave}) => {

    const {formik, purposes, payments, categories, stations, employee } = useInspectionRequestContainer({afterSave})

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
        layout: "horizontal",
    };

    return (<Modal
        title={"Добавить заявку на техосмотр"}
        visible={show}
        onCancel={onCancel}
        destroyOnClose={true}
        okText={"Сохранить"}
        cancelText={"Отмена"}
        onOk={formik.handleSubmit}
    >
        <FormikProvider value={formik}>
            <AntdForm {...layout}>
                <AntdForm.Item name="registration_number" label="Гос. номер">
                    <Input name="registration_number" />
                </AntdForm.Item>

                <AntdForm.Item name="brand" label="Марка">
                    <Input name="brand" />
                </AntdForm.Item>

                <AntdForm.Item name="model" label="Модель">
                    <Input name="model" />
                </AntdForm.Item>

                <AntdForm.Item name="purpose" label="Применение">
                    <Select name="purpose">
                        {purposes
                            .map((purpose) => (
                                <Select.Option key={purpose.value} value={purpose.value}>
                                    {purpose.title}
                                </Select.Option>
                            ))}
                    </Select>
                </AntdForm.Item>

                <AntdForm.Item name="category" label="Категория">
                    <Select name="category">
                        {categories
                            .map((category) => (
                                <Select.Option key={category.value} value={category.value}>
                                    {category.title}
                                </Select.Option>
                            ))}
                    </Select>
                </AntdForm.Item>

                <AntdForm.Item name="payer" label="Плательщик">
                    <Input name="payer" />
                </AntdForm.Item>

                <AntdForm.Item name="payer_phone_number" label="Телефон">
                    <Input name="payer_phone_number" />
                </AntdForm.Item>

                <AntdForm.Item name="station_id" label="Станция ПТО">
                    <Select name="station_id">
                        {stations
                            .map((station) => (
                                <Select.Option key={station.id} value={station.id}>
                                    {station.name}
                                </Select.Option>
                            ))}
                    </Select>
                </AntdForm.Item>

                <AntdForm.Item name="payment_type" label="Тип оплаты">
                    <Select name="payment_type">
                        {payments
                            .map((payment) => (
                                <Select.Option key={payment.value} value={payment.value}>
                                    {payment.title}
                                </Select.Option>
                            ))}
                    </Select>
                </AntdForm.Item>

                <AntdForm.Item name="client_price" label="Цена для клиента">
                    <Input name="client_price" type={"number"} />
                </AntdForm.Item>
            </AntdForm>

        </FormikProvider>

    </Modal>);
}

export default Form;
