import React, { useLayoutEffect } from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import RequestsRouter from '../inspection/requests'

const availableRoles = ["admin", "employee", "company", "partner", "companyEmployee"];

const Index = () => {
  const history = useHistory();
  const [role] = useSelector((store) => store.user.roles);

  useLayoutEffect(() => {
    if (!availableRoles.includes(role)) history.replace("/auth");
  }, [role, history]);

  if (!availableRoles.includes(role)) return null;

  return (
    <Switch>
      <Route path={"/app/inspection/requests"} component={RequestsRouter} />
    </Switch>
  );
};

export default Index;
