import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import app from './app';
import user from './user'
import inspection from './inspection'

const reducers = combineReducers({
  app,
  user,
  inspection
});

const initialState = {};

const store = createStore(reducers, initialState, applyMiddleware(thunk));

export default store;
