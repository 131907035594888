import React from "react";
import useLoginContainer from "../../../hooks/useLoginContainer";
import { FormikProvider } from "formik";
import styles from "./styles.module.scss";
import { Form, Input, SubmitButton } from 'formik-antd'
import {Card, Typography} from "antd";

const Login = () => {
  const formik = useLoginContainer();

  return (
    <div className={styles.page}>
      <FormikProvider value={formik}>
        <Card>

          <Typography.Title level={3} className={styles.title}>Вход в систему</Typography.Title>

          <Form labelCol={{span: 6}} wrapperCol={{span: 18}}>

            <Form.Item name="login" label="Логин" rules={[{ required: true }]}>
              <Input name='login'  />
            </Form.Item>

            <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
              <Input.Password name='password'  />
            </Form.Item>

            <SubmitButton block type={'primary'}>Войти</SubmitButton>

          </Form>
        </Card>
      </FormikProvider>
    </div>
  );
};

export default Login;
