import Api from "../services/api";

export const types = {
  SET_DATA: "APP/SET_DATA",
};

const initialState = {
  roles: [
    { name: "admin", title: "Админ", color: "gold" },
    { name: "employee", title: "Сотрудник", color: "blue" },
    { name: "partner", title: "Партнер", color: "purple" },
    { name: "company", title: "Компания", color: "green" },
    { name: "companyEmployee", title: "Сотрудник компании", color: "green" },
  ],
  employeePositions: [],
  employee: [],
  stations: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

export const actions = {
  setData: (data) => async (dispatch) => {
    await dispatch({
      type: types.SET_DATA,
      payload: data,
    });
  },

  load: () => async (dispatch) => {
    // Get user information
    const { data: positions } = await Api("users/positions?no-cache");

    const { data: employee } = await Api("users/employee?no-cache");

    const { data: stations } = await Api("stations/list?no-cache");

    console.log("update info!!");
    console.log(employee, positions, stations);

    await dispatch({
      type: types.SET_DATA,
      payload: {
        positions,
        employee,
        stations,
      },
    });
  },
};
