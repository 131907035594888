import React from "react";
import { Layout, Button, Modal } from "antd";
import { ReactComponent as Logo } from "./logo.svg";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../store/user";
import { useHistory } from "react-router-dom";

const { Header } = Layout;

const AppHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { login } = useSelector((store) => store.user);

  const onLogoutClick = () => {
    Modal.confirm({
      title: "Выход из аккаунта",
      content: "Вы уверены, что хотите выйти?",
      okText: "Выход",
      cancelText: "Отмена",
      onOk: logout,
    });
  };

  const logout = () => {
    dispatch(actions.signOut());
    history.replace("/auth");
  };

  return (
    <Header className={styles.header}>
      <div className={styles.logo}>
        <Logo /> Автоспея
      </div>
      {login && (
        <Button type="link" onClick={onLogoutClick}>
          Выход ({login})
        </Button>
      )}
    </Header>
  );
};

export default AppHeader;
