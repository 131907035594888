import axios from "axios";
import { setupCache } from "axios-cache-adapter";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    paths: ['no-cache']
  }
});

const api = axios.create({
  adapter: cache.adapter,
});

const Api = async (url = "", params = {}, method = "get", headers = {}) => {
  const store = (await import("../store/index")).default;

  const token = store.getState().user.isAuthorized
    ? store.getState().user.token
    : null;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  headers.Accept = "application/json";

  return api({
    url,
    method,
    headers,
    params: method === "get" ? params : null,
    data: method !== "get" ? params : null,
    baseURL: process.env.REACT_APP_API_SERVER_ENDPOINT,
  });
};

export default Api;
